import React from 'react'

import PhotoItem from '../PhotoItem/PhotoItem'

const PhotoItems = (props) => {
  const isMobile = window.checkIsMobile()
  const isOdd = props.dataList.length % 2 === 1 && !isMobile
  return (
    <>
    {
      props.dataList.map((item, index) => {
        index = props.dataList.length - index - 1
        return (
          <PhotoItem
            key={index}
            className={(isOdd ? 'center' : '') + (isMobile ? ' photo-item-phone' : '')}
            imagePath={'/articlePhotos/' + item.imageName}
            describe={item.describe}
            link={item.url}
          />
        )
      })
    }
    </>
  )
}

export default PhotoItems
