import React, { useState } from 'react'
import './Menu.css'
import './Menu.scoped.css'

import { Button, Dropdown, Menu } from 'antd'
import { HomeOutlined, BulbOutlined, BookOutlined, HeartOutlined, MessageOutlined, BarsOutlined, CloseOutlined } from '@ant-design/icons'
import { English, Fan, NurseCap, Video } from '@icon-park/react'

import LogoImg from './LogoImg/LogoImg'

const string2ChineseMap = {
  Experience_sharing: '重说纷纭',
  Department_culture: '科室文化',
  Document_sharing: '文献分享',
  Medical_science_popularization: '医学科普',
  Psychological_words: '心理话',
  Intensive_care: '重症护理',
  Medical_English: '医学英语',
  Video: '宣传片'
}

const AppMenu = (props) => {
  const [isOpen, setIsOpen] = useState(false)

  const changeUrl = (kind) => {
    setIsOpen(false)
    props.setUrl(kind)
  }

  const items = [
    {
      label: <a onClick={() => changeUrl('')}>首页</a>,
      key: 'Home',
      icon: <HomeOutlined />
    }, {
      label: <a onClick={() => changeUrl('Experience_sharing')}>重说纷纭&nbsp;</a>,
      key: 'Experience_sharing',
      icon: <MessageOutlined />
    }, {
      label: <a onClick={() => changeUrl('Department_culture')}>科室文化&nbsp;</a>,
      key: 'Department_culture',
      icon: <Fan className="anticon" theme="outline" size="14" fill="currentColor"/>
    }, {
      label: <a onClick={() => changeUrl('Document_sharing')}>文献分享&nbsp;</a>,
      key: 'Document_sharing',
      icon: <BookOutlined />
    }, {
      label: <a onClick={() => changeUrl('Medical_science_popularization')}>医学科普&nbsp;</a>,
      key: 'Medical_science_popularization',
      icon: <BulbOutlined />
    }, {
      label: <a onClick={() => changeUrl('Psychological_words')} >心理话&nbsp;</a>,
      key: 'Psychological_words',
      icon: <HeartOutlined />
    }, {
      label: <a onClick={() => changeUrl('Intensive_care')}>重症护理&nbsp;</a>,
      key: 'Intensive_care',
      icon: <NurseCap className="anticon" theme="outline" size="14" fill="currentColor"/>
    }, {
      label: <a onClick={() => changeUrl('Medical_English')}>医学英语&nbsp;</a>,
      key: 'Medical_English',
      icon: <English className="anticon" theme="outline" size="14" fill="currentColor"/>
    }, {
      label: <a onClick={() => changeUrl('Video')}>宣传片&nbsp;</a>,
      key: 'Video',
      icon: <Video className="anticon" theme="outline" size="14" fill="currentColor"/>
    }
  ]

  return (
    <>
      {
        !window.checkIsMobile() &&
        <Menu
          className="ant-main-menu"
          mode="horizontal"
          items={items}
          selectedKeys={props.kind}
          onClick={(e) => changeUrl(e.key)}
        />
      }
      {
        window.checkIsMobile() &&
        <Dropdown
          className="dropdown"
          menu={{ items }}
          trigger={['click']}
          open={isOpen}
          onOpenChange={() => setIsOpen(!isOpen)}
        >
          <Button onClick={() => setIsOpen(!isOpen)} icon={isOpen ? <CloseOutlined /> : <BarsOutlined />}/>
        </Dropdown>
      }
      <section className={'title ' + (props.kind === 'Psychological_words' || props.kind === 'Video' ? 'thin' : 'normal')} >
        <LogoImg setUrl={props.setUrl}/>
        <h1 >{string2ChineseMap[props.kind]}</h1>
      </section>
    </>
  )
}

export default AppMenu
