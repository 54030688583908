import React, { useEffect, useState } from 'react'
import 'antd/dist/reset.css'
import './App.css'

import { ConfigProvider, theme } from 'antd'

import HomeButtons from './components/HomeButtons/HomeButtons'
import Articles from './components/Articles/Articles'
import Floats from './components/Floats/Floats'
import Footer from './components/Footer/Footer'
import AppMenu from './components/AppMenu/AppMenu'
import Video from './components/Video/Video'

if (!window.checkIsMobile()) {
  document.documentElement.style.minWidth = '650px'
}

const App = () => {
  const [isDark, setIsDark] = useState(window.matchMedia('(prefers-color-scheme: dark)').matches)
  const [kind, setKind] = useState(window.judgeKind())

  useEffect(() => {
    setKind(window.judgeKind())
    window.addEventListener('popstate',
      () => {
        setKind(window.judgeKind())
      }
      , false)
  }, [])

  const setUrl = (newKind) => {
    let newUrl = '?kind=' + newKind
    if (newKind === '') { newUrl = window.location.origin }
    const state = { title: '望江重症', url: newUrl }
    window.history.pushState(state, '望江重症', newUrl)
    setKind(newKind)
  }

  window.matchMedia('(prefers-color-scheme: dark)')
    .addEventListener('change', () => {
      setIsDark(window.matchMedia('(prefers-color-scheme: dark)').matches)
    })

  return (
    <div className="App">
      <ConfigProvider theme={{ algorithm: isDark ? theme.darkAlgorithm : theme.defaultAlgorithm }}>
        {kind !== '' && <AppMenu setUrl={setUrl} kind={kind}/>}
        {kind === '' && <HomeButtons setUrl={setUrl}/>}
        {kind !== 'Video' && <Articles kind={kind}/>}
        {kind === 'Video' && <Video />}
        <Floats />
        <Footer />
      </ConfigProvider>
    </div>
  )
}

export default App
