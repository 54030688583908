import React, { useEffect, useState } from 'react'
import './Articles.scoped.css'

import { Pagination } from 'antd'

import articlesDataList from '../../assets/articlesData'
import PhotoItems from './PhotoItems/PhotoItems'

const string2numMap = {
  '': -1,
  Experience_sharing: 0,
  Department_culture: 1,
  Document_sharing: 2,
  Medical_science_popularization: 3,
  Psychological_words: 4,
  Intensive_care: 5,
  Medical_English: 6
}

const Articles = (props) => {
  const [current, setCurrent] = useState(1)
  const pageSize = window.checkIsMobile() ? 10 : 20
  const onChange = (page) => {
    setCurrent(page)
  }
  const numKind = string2numMap[props.kind]
  const [articlesDataListByKind, setArticlesDataListByKind] = useState([])

  useEffect(() => {
    if (numKind === -1) {
      setArticlesDataListByKind(articlesDataList)
    } else {
      let tempList = []
      for (let i = 0; i < articlesDataList.length; i++) {
        if (articlesDataList[i].kind === numKind) {
          tempList = tempList.concat([articlesDataList[i]])
        }
      }
      setArticlesDataListByKind(tempList)
    }
  }, [props.kind])

  return (
    <>
      <div className={'articles' + (window.checkIsMobile() ? ' articles-phone' : '')}>
        <PhotoItems
          dataList={articlesDataListByKind.slice((current - 1) * pageSize, current * pageSize)}
        />
      </div>
      {
        articlesDataListByKind.length > pageSize &&
        <Pagination
          current={current}
          onChange={onChange}
          style={{ textAlign: 'center', paddingBottom: '10px' }}
          total={articlesDataListByKind.length}
          pageSize={pageSize}
          size={'default'}
        />
      }
    </>

  )
}

export default Articles
