import React, { useEffect, useState } from 'react'
import './HomeButtons.scoped.css'

const HomeButtons = (props) => {
  const [imageCount, setImageCount] = useState(1)
  const isMobile = window.checkIsMobile()
  useEffect(() => {
    setInterval(() => {
      setImageCount(window.homePhotoCount)
    }, 1000)
  }, [])
  return (
    <div className={'home-buttons' + (isMobile ? ' home-buttons-phone' : '')}>
      <div className={'home-photos ' + (isMobile ? ' home-photos-phone' : '')}>
        <div className="background"></div>
        <img
          className="photos"
          src={'/homePhotos/' + imageCount.toString() + '.jpg'}
        />
      </div>

      <div className={'context' + (isMobile ? ' context-phone' : '')}>
        <section className="title">
          <img src="/favicon.svg" alt="icon"/>
          <h1>望江重症</h1>
        </section>

        <p>浙江省人民医院</p>

        <div className="buttons">
          <div className="eachline orange">
            <div className="button _25" onClick={() => props.setUrl('Experience_sharing')}>
              <a>重说纷纭</a>
            </div>
            <div className="button _25" onClick={() => props.setUrl('Document_sharing')}>
              <a>文献分享</a>
            </div>
            <div className="button _25" onClick={() => props.setUrl('Medical_science_popularization')}>
              <a>医学科普</a>
            </div>
          </div>

          <div className="eachline green">
            <div className="button _25" onClick={() => props.setUrl('Psychological_words')}>
              <a>心理话</a>
            </div>
            <div className="button _25" onClick={() => props.setUrl('Intensive_care')}>
              <a>重症护理</a>
            </div>
            <div className="button _25" onClick={() => props.setUrl('Medical_English')}>
              <a>医学英语</a>
            </div>
          </div>

          <div className="eachline luminous">
            <div className="button _40" onClick={() => props.setUrl('Department_culture')}>
              <a>科室文化</a>
            </div>
            <div className="button _40" onClick={() => props.setUrl('Video')}>
              <a>科室宣传片</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HomeButtons
