import React from 'react'
import './LogoImg.scoped.css'

const LogoImg = (props) => {
  return (
    <div className="img" onClick={() => props.setUrl('')}>
      <img src="/favicon.svg" alt="icon"/>
      <p>望江重症</p>
    </div>
  )
}

export default LogoImg
