import React from 'react'
import './PhotoItem.scoped.css'

const PhotoItem = (props) => {
  return (
    <div className={props.className + ' photo-item'} onClick={() => window.open(props.link)}>
      <img src={props.imagePath} alt={props.describe}/>
      <div>
        <p>{props.describe}</p>
      </div>
    </div>
  )
}

export default PhotoItem
