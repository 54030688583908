import React, { useState } from 'react'
import './Footer.scoped.css'

import { CopyToClipboard } from 'react-copy-to-clipboard'
import { message } from 'antd'

const Footer = () => {
  const [messageApi, contextHolder] = message.useMessage()

  const [isFixed, setIsFixed] = useState(document.body.offsetHeight <= document.documentElement.offsetHeight)
  window.updateFooter = () => {
    setIsFixed(document.body.offsetHeight <= document.documentElement.offsetHeight)
  }
  window.onresize = window.updateFooter
  setInterval(window.updateFooter, 5)

  const onCopy = () => {
    messageApi.open({
      type: 'success',
      content: '复制成功，去微信粘贴'
    })
    setTimeout(() => window.open('weixin://', '_self'), 1000)
  }
  return (
    <>
      {contextHolder}
      <footer className={isFixed ? 'fixed' : 'absolute'}>
        <p>
          微信号：
          <CopyToClipboard text="srmwjzz123" onCopy={onCopy}>
            <a>srmwjzz123</a>
          </CopyToClipboard>
        </p>
        <p>
          联系电话：
          <a href="tel:18358143508">18358143508</a>
          &nbsp;
          <a href="tel:0571-87312789">0571-87312789</a>
        </p>
        <p>
          邮箱：
          <a href="mailto:hi@wangjiangicu.eu.org">hi@wangjiangicu.eu.org</a>
        </p>
        <p>
          地址：
          <a
            href="http://api.map.baidu.com/geocoder?address=浙江省人民医院(望江山院区)&output=html&src=我的位置"
            target="_blank" rel="noreferrer"
          >
            浙江省杭州市西湖区转塘街道望江山路1号
          </a>
        </p>
      </footer>
      <div className="footer"></div>
    </>
  )
}

export default Footer
