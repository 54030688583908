import React from 'react'

import { FloatButton, Popover, QRCode } from 'antd'
import { QrcodeOutlined } from '@ant-design/icons'

const Floats = () => {
  return (
    <FloatButton.Group>
      <FloatButton.BackTop />
      <Popover
        placement="leftBottom"
        content={
          <QRCode
            errorLevel="H"
            value="http://weixin.qq.com/r/sRwLDonEGUFlKM1tb0k1"
            icon="/favicon.svg"
          />
        }
        trigger={window.checkIsMobile() ? 'click' : 'hover'}
      >
        <FloatButton icon={<QrcodeOutlined />}/>
      </Popover>
    </FloatButton.Group>
  )
}

export default Floats
