const newObject = (url, describe, kind, imageName = '0.jpg') => {
  return { url, describe, kind, imageName }
}

/*
 * kind: number
 *   0 : Experience Sharing              重说纷纭
 *   1 : Department Culture              科室文化
 *   2 : Document Sharing                文献分享
 *   3 : Medical Science Popularization  医学科普
 *   4 : Psychological Words             心理话
 *   5 : Intensive Care                  重症护理
 *   6 : Medical English                 医学英语
 */

const articlesDataList = [
  newObject(
    'https://mp.weixin.qq.com/s/FhEk7EEX_a-R0JvU1qb9iw',
    '中国医药教育协会----“绿色望江 重症远航”学术交流会会议日程',
    1
  ),
  newObject(
    'https://mp.weixin.qq.com/s/NjAtlO2SE5FVMMO2lP1YQw',
    '新年新景新愿，万物更新，旧疾当愈，岁月常如新',
    1
  ),
  newObject(
    'https://mp.weixin.qq.com/s/Ef3CNpRkLAi5CZ_1rux8aA',
    '胸闷气急，竟是它们搞的鬼！',
    3
  ),
  newObject(
    'https://mp.weixin.qq.com/s/4SuWA9DaiG6wjoHKgnQ7Iw',
    '成人脓毒症患者出院后发生心血管事件的风险',
    2
  ),
  newObject(
    'https://mp.weixin.qq.com/s/qC88fUmQww8xUQrpS41LMA',
    '下腔静脉超声与危重症患者容量管理评估',
    0
  ),
  newObject(
    'https://mp.weixin.qq.com/s/eZza3k9SOLVXsm2-9iJuZA',
    '脖子上的“肚脐眼”，是他的奋斗勋章',
    1
  ),
  newObject(
    'https://mp.weixin.qq.com/s/C7Ih_-xTsfesulSXTIlTfA',
    '气温骤降请注意！！！老慢支们，别让咳、痰、喘缠上你',
    3
  ),
  newObject(
    'https://mp.weixin.qq.com/s/60S02zXnIQgpwepBmAAZvA',
    '脓毒性休克血流动力学支持',
    2
  ),
  newObject(
    'https://mp.weixin.qq.com/s/5FLRtO82riPNzPWidSTU5Q',
    '重症监护室脓毒症患者的高动力左心室射血分数',
    2
  ),
  newObject(
    'https://mp.weixin.qq.com/s/XUgsU1mPUvmDv7slZBs7jA',
    '这个医师节，我们有话说！',
    1
  ),
  newObject(
    'https://mp.weixin.qq.com/s/n25l9taBRYNa6Vc_t22v8w',
    '低年资护士遇到紧急气管插管就大脑空白！别怕，看这篇~',
    5
  ),
  newObject(
    'https://mp.weixin.qq.com/s/rbn3iri9seWkO-YrlBjJDw',
    '火炎焱燚---中暑是小事吗？警惕热射病！',
    3
  ),
  newObject(
    'https://mp.weixin.qq.com/s/xvHtMc2ncIEBWVuSF7rWVA',
    '我们的继教班来了----《老年重症疾病的诊治规范与进展》',
    1
  ),
  newObject(
    'https://mp.weixin.qq.com/s/5KkY3A8EgrPaojPyLoBP6A',
    '第三届“绿色望江 重症远航”学术交流会会议日程',
    1
  ),
  newObject(
    'https://mp.weixin.qq.com/s/SruQxktqyVOVYzNm8wrUQQ',
    '危重病人中维生素 C的测量： 临床重要性和实际测量困难',
    2
  ),
  newObject(
    'https://mp.weixin.qq.com/s/OFDecjAKS6G_6c84xSX-TA',
    '5.12护士节特别报道 | 致敬逆行抗疫天使',
    1
  ),
  newObject(
    'https://mp.weixin.qq.com/s/pUExzkyRHuFjyFqFBm57Zw',
    '【医学英语】- 院外心跳骤停后的上消化道缺血性损伤',
    6
  ),
  newObject(
    'https://mp.weixin.qq.com/s/fwzdxZowNDwrskkVJq4VDA',
    '晚一步就可能有生命危险，竟然是肾不好惹的祸！',
    3
  ),
  newObject(
    'https://mp.weixin.qq.com/s/IpCqzjI8zRWNEcvwolJKmA',
    '恩格列净对射血分数保留型心力衰竭的治疗',
    2
  ),
  newObject(
    'https://mp.weixin.qq.com/s/81i2QryACeoWMbduATX8uQ',
    '迎新年丨 重症家文化 温暖望江情',
    1
  ),
  newObject(
    'https://mp.weixin.qq.com/s/PLjk2GmUuEn2Yj4uiEO13A',
    '【医学英语】-ICI',
    6
  ),
  newObject(
    'https://mp.weixin.qq.com/s/TW3dqON3GieYmQVaLJ379A',
    '望江重症故事 那一道别样风景',
    1
  ),
  newObject(
    'https://mp.weixin.qq.com/s/v8dOOv7LcWeB5KKqgsHT6A',
    '降钙素原知多少？',
    0
  ),
  newObject(
    'https://mp.weixin.qq.com/s/LuIfTeO6oqvjSFXMFW6V5w',
    '66岁再次蹒跚学步，历时15个月，终在家团聚！',
    1
  ),
  newObject(
    'https://mp.weixin.qq.com/s/N55xQv2Qjue_eekpZuDNVQ',
    '《老年重症疾病的诊治规范与进展》--我们的继教课程火热进行中！',
    1
  ),
  newObject(
    'https://mp.weixin.qq.com/s/jR8M0194YyrsfgTEh5y8EA',
    '【医学英语】--- Prone Position',
    6
  ),
  newObject(
    'https://mp.weixin.qq.com/s/6ZTFr1hRRKgqYDx34BJBew',
    '8.19医师节，医者心理话—医人亦自医',
    4
  ),
  newObject(
    'https://mp.weixin.qq.com/s/pH6GS7Vk35v7x6tDz-focg',
    '【老年重症疾病的诊治规范及进展】--我们的继教课程来啦！',
    1
  ),
  newObject(
    'https://mp.weixin.qq.com/s/8NFynw7NBC7GDq4O79vQKQ',
    '第二届“绿色望江 重症远航”学术交流会圆满召开',
    1
  ),
  newObject(
    'https://mp.weixin.qq.com/s/2qK4qPCP3Jylo5vjhImu-A',
    'TO BE OR NOT TO BE————爱而抉择之气管切开',
    3
  ),
  newObject(
    'https://mp.weixin.qq.com/s/3IInltLPhFxr43oqA6Hh_g',
    '致敬最可爱的人',
    1
  ),
  newObject(
    'https://mp.weixin.qq.com/s/v5lr7t_tLqC5R6Tg-DIGSg',
    '512天使心理话～让天使翅膀更强壮（文末有彩蛋）',
    4
  ),
  newObject(
    'https://mp.weixin.qq.com/s/3M7HXcQzIYw1ecOB_rEr3A',
    '脓毒症患者机械通气时的镇静治疗----右美托咪定VS丙泊酚',
    2
  ),
  newObject(
    'https://mp.weixin.qq.com/s/otzcIx5oNRiEpQeQboKM6Q',
    '【医学英语】（一）',
    6
  ),
  newObject(
    'https://mp.weixin.qq.com/s/aIyhgVtJealK70XQ6vS3bw',
    '以心为灯，用爱守护——带您认识重症护理',
    5
  ),
  newObject(
    'https://mp.weixin.qq.com/s/-StyEuXuxON_k4HRWZ6scQ',
    '团团圆圆｜元宵佳节心理话',
    4
  ),
  newObject(
    'https://mp.weixin.qq.com/s/AhDIVtCcrWVzZdMfowvCvg',
    '金牛迎春｜今儿个大年初一｜值此新春佳节望江重症携ICU-综合支持科全体医护人员给大家拜年啦！',
    1
  ),
  newObject(
    'https://mp.weixin.qq.com/s/qA0wZnFUb399UbR-QM6PJw',
    '低氧血症型呼吸衰竭患者动脉氧分压治疗目标对预后的影响',
    2
  ),
  newObject(
    'https://mp.weixin.qq.com/s/UVeVlT-wY90ithtB6gdGIA',
    '气温忽高忽低，警惕血压过山车',
    3
  ),
  newObject(
    'https://mp.weixin.qq.com/s/LGOBidd3X7ORissJ8QniMA',
    '50岁男子心跳骤停！床边康复治疗让他恢复正常生活',
    3
  ),
  newObject(
    'https://mp.weixin.qq.com/s/XESMlkWXzE2Dx3clHxBFrQ',
    '望江重症——2021元旦寄语',
    1
  ),
  newObject(
    'https://mp.weixin.qq.com/s/rcM9niomL9IMRFIgNYcKeQ',
    '乘风破浪，重症远航——今天，我们有公众号啦！！',
    1
  )
]

for (let i = 0; i < articlesDataList.length; i++) {
  articlesDataList[i].imageName = (articlesDataList.length - i - 1).toString() + '.jpg'
}

export default articlesDataList
