import React from 'react'
import './Video.scoped.css'

const Video = () => {
  return (
    <iframe
      className="video"
      frameBorder='no'
      allowFullScreen
      mozallowfullscreen
      webkitallowfullscreen
      src='https://go.plvideo.cn/front/video/preview?vid=b7248d5d45de31c93890f1dddb15e71b_b'
    />
  )
}

export default Video
